@import "core.scss";
* {
  box-sizing: border-box;
}

body {
  font-size: 4vw;
  font-family: "MyriadProRegular", serif;
}
@media(min-width: 768px) {
  body {
    font-size: 0.99vw;
    font-family: "MyriadProRegular", serif;
  }
}

p {
  margin: 0 0 0.5em 0;
}

.scrollTrack {
  position: absolute;
  width: 3px !important;
  transition: opacity 200ms;
  right: 10px;
  top: 7%;
  height: 80%;
  cursor: pointer;
  &:before {
    content: '';
    position: absolute;
    width: 1px;
    height: 110%;
    background-color: #c0bcbc;
    top: -5%;
    left: 1px;
  }
}
.scrollThumb {
  width: 3px;
  //height: 16px !important;
  background-color: #949191;
  border-radius: 1px;
  overflow: hidden;
}


textarea:focus, input:focus {
  outline: none;
}
::-webkit-input-placeholder { /* WebKit browsers */
  color: #210800;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color: #210800;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: #210800;
}
:-ms-input-placeholder { /* Internet Explorer 10+ */
  color: #210800;
}

.overflow-container {
  width: 100%;
  overflow: hidden;
}
//библиотека элементов
.customSelect.select {
  .control {
    border-color: #d9d8d8 !important;
  }
}
.common-input-block {
  display: block;
  margin-bottom: 2em;
  position: relative;
  input[type="text"].form-element, input[type="tel"].form-element  {
    background-color: white;
    border: 1px solid #fbaf5d;
    width: 100%;
    font-size: 1em;
    line-height: 3.1;
    border-radius: 2em;
    padding: 0 1em 0 1em;
  }
  input:valid + .label, input:focus + .label {
    display: none;
  }
  .label {
    position:absolute;
    font-size: 1em;
    line-height: 3.1;
    left: 0;
    top: 0;
    color: #898989;
    padding: 0 1em 0 1em;
    span {
      color: #ff2626;
    }
  }
  .label-title {
    font-size: 1em;
    line-height: 3.1;
    color: #898989;
    span {
      color: #ff2626;
    }
  }
  .radio-group {
    display: inline-block;
    white-space: nowrap;
    margin-right: 1em;
    input {
      display: none;
    }
    label {
      color: black;
      .point {
        content: '';
        display: inline-block;
        width: 1.4em;
        height: 1.4em;
        margin-right: 1em;
        background-image: url('./assets/images/radio-element.png');
        background-size: cover;
        vertical-align: middle;
      }
      display: inline-block;
      vertical-align: middle;
    }

    input:checked + .point {
        background-size: cover;
        background-position: left 100%;
    }
  }
}
.form-element.outer-info {
  font-style: italic;
  color: #9c9c9c;
  font-size: 0.9em;
  line-height: 3.1;
  padding: 0 1em 0 1em;
  span {
    color: #ff2626;
  }
}

.with-rouble {
  &:after {
    content: '₽';
    display: inline-block;
    vertical-align: inherit;
    font-family: Rouble;
    font-size: 0.8em;
    position: relative;
    font-weight: 300;
    margin-left: 0.1em;
  }
}