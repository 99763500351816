:global {
  .viewCatalogueComp {
    margin-top: 15%;
    border-top: 1px solid transparent;
  }
  .catalogueComp {
    overflow: hidden;
    &__staffItem {
      padding: 3% 7% 0 7%;
      overflow: hidden;
      position: relative;
      z-index: 70;
      .image-gallery-swipe {
        border: 1px solid #d9d8d8;
      }
      .image-gallery-left-nav, .image-gallery-right-nav {
        color: #fff;
        font-size: 1.6em;
        padding: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      .image-gallery-left-nav {
        left: -1em;
      }
      .image-gallery-right-nav {
        right: -1em;
      }
      .image-gallery-fullscreen-button:before, .image-gallery-left-nav:before, .image-gallery-play-button:before, .image-gallery-right-nav:before {
        color: #e4e4e4;
        line-height: .7;
        text-shadow: none;
        transition: color .2s ease-out;
        border: 1px solid transparent;
      }
      .image-gallery-thumbnails .image-gallery-thumbnails-container {
        text-align: left;

      }
      .image-gallery-thumbnail {
        width: 22%;
        border: 1px solid transparent;
      }
      .image-gallery-thumbnail.active {
        border: 1px solid #337ab7;
      }
    }
    &__backButton {
      margin-bottom: 2%;
      a {
        color: #878b98;
        text-decoration: none;
        font-size: 1em;
        line-height: 1.2;
        span {
          font-size: 1.6em;
          vertical-align: middle;
          margin-right: 0.5em;
        }
      }
    }
    .image-gallery-slide {
      text-align: center;
      img {
        width: 100%;
        height: auto;
      }

    }
    &__gallery {
      float: left;
      width: 100%;
      margin-bottom: 2%;
      .mainImage {
        width: 100%;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    &__name {
      float: right;
      width: 100%;
      margin-bottom: 2%;
      overflow: hidden;
      .title {
        padding-left: 2%;
        font-size: 1.6em;
        width: 50%;
        float: left;
      }
      .base_field {
        padding-left: 2%;
        width: 50%;
        float: left;
      }
      .instruction_field {
        width: 40%;
        float: right;
        text-decoration: none;
        text-align: center;
        padding: 0 1em;
        line-height: 1.6;
        color: #fdbe06;
        border: 1px solid #fdbe06;
      }
    }
    &__fields {
      float: right;
      width: 100%;
      .header {
        padding-left: 2%;
        font-size: 1.2em;
        line-height: 1.6;
        margin-bottom: 2%;
      }
      .description {
        font-size: 0.8em;
      }
      .fields {
        .field {
          white-space: nowrap;
          padding: 2%;
          color: #2d2d2d;
          font-size: 0.8em;
          &:nth-child(even) {
            background-color: #e3e3e3;
          }
          div {
            display: inline-block;
            white-space: normal;
            width: 50%;
            vertical-align: middle;
          }
        }
      }
    }
    &__complectation {
      width: 100%;
      clear: both;
      margin-bottom: 4%;
      .title {
        font-size: 1.2em;
        line-height: 1.6;
        margin-bottom: 2%;
      }
    }
    &__contentfields {
      width: 100%;
      clear: both;
      text-align: left;
      padding-left: 4%;
      display: flex;
      > div {
        width: 46%;
        margin: 0 6% 6% 0;
        vertical-align: top;
        &:empty {
          display: none;
        }
        &:nth-child(even) {
          text-align: left;
          width: 40%;
        }
        &:nth-child(odd) {
          text-align: right;
        }
        &.images {
          div {
            display: inline-block;
            width: 45%;
            height: auto;
            vertical-align: top;
            margin: 1%;
            img {
              width: 100%;
              height: auto;
            }
          }

          &.single {
            div {
              width: 100%;
            }
            img {
              display: block;
              width: 100%;
              height: auto;
            }
          }
        }
        &.text {
          strong {
            font-size: 1.7em;
          }
        }
      }
    }
  }
  @media(min-width: 768px) {
    .viewCatalogueComp {
      margin-top: 0;
    }
    .catalogueComp {
      overflow: hidden;
      &__staffItem {
        padding: 3% 12% 0 7%;
        overflow: hidden;
        .image-gallery-swipe {
          border: 1px solid #d9d8d8;
        }
        .image-gallery-left-nav, .image-gallery-right-nav {
          color: #fff;
          font-size: 1.6em;
          padding: 0;
          top: 50%;
          transform: translateY(-50%);
        }
        .image-gallery-left-nav {
          left: -1em;
        }
        .image-gallery-right-nav {
          right: -1em;
        }
        .image-gallery-fullscreen-button:before, .image-gallery-left-nav:before, .image-gallery-play-button:before, .image-gallery-right-nav:before {
          color: #e4e4e4;
          line-height: .7;
          text-shadow: none;
          transition: color .2s ease-out;
          border: 1px solid transparent;
        }
        .image-gallery-thumbnails .image-gallery-thumbnails-container {
          text-align: left;

        }
        .image-gallery-thumbnail {
          width: 22%;
          border: 1px solid transparent;
        }
        .image-gallery-thumbnail.active {
          border: 1px solid #337ab7;
        }
      }
      &__backButton {
        margin-bottom: 2%;
        a {
          color: #5d5d5d;
          text-decoration: none;
          font-size: 1em;
          line-height: 1.2;
          display: flex;
          align-items: center;

          span {
            font-size: 1.6em;
            margin-right: 0.5em;
          }
        }
      }
      .image-gallery-slide {
        text-align: center;
        img {
          width: 100%;
          height: auto;
        }

      }
      &__gallery {
        float: left;
        width: 49%;
        margin-bottom: 2%;
        .mainImage {
          width: 100%;
          img {
            width: 100%;
            height: auto;
          }
        }
      }
      &__name {
        float: right;
        width: 45%;
        margin-bottom: 2%;
        overflow: hidden;
        .title {
          padding-left: 2%;
          font-size: 1.6em;
          width: 50%;
          float: left;
        }
        .base_field {
          padding-left: 2%;
          width: 50%;
          float: left;
        }
        .instruction_field {
          width: 40%;
          float: right;
          text-decoration: none;
          text-align: center;
          padding: 0 1em;
          line-height: 1.6;
          color: #fdbe06;
          border: 1px solid #fdbe06;
        }
      }
      &__fields {
        float: right;
        width: 45%;
        .header {
          padding-left: 2%;
          font-size: 1.2em;
          line-height: 1.6;
          margin-bottom: 2%;
        }
        .description {
          font-size: 0.8em;
        }
        .fields {
          .field {
            white-space: nowrap;
            padding: 2%;
            color: #2d2d2d;
            font-size: 0.8em;
            &:nth-child(even) {
              background-color: #e3e3e3;
            }
            div {
              display: inline-block;
              white-space: normal;
              width: 50%;
              vertical-align: middle;
            }
          }
        }
      }
      &__complectation {
        width: 49%;
        clear: both;
        margin-bottom: 4%;
        .title {
          font-size: 1.2em;
          line-height: 1.6;
          margin-bottom: 2%;
        }
      }
      &__contentfields {
        width: 100%;
        clear: both;
        text-align: left;
        padding-left: 4%;
        > div {
          display: inline-block;
          width: 46%;
          margin: 0 6% 6% 0;
          vertical-align: top;
          &:nth-child(even) {
            text-align: left;
            width: 40%;
          }
          &:nth-child(odd) {
            text-align: right;
          }
          &.images {
            div {
              display: inline-block;
              width: 45%;
              height: auto;
              vertical-align: top;
              margin: 1%;
              img {
                width: 100%;
                height: auto;
              }
            }

            &.single {
              div {
                width: 100%;
              }
              img {
                display: block;
                width: 100%;
                height: auto;
              }
            }
          }
          &.text {
            strong {
              font-size: 1.7em;
            }
          }
        }
      }
    }
  }
}
.staffItem {
  display: flex;
  flex-direction: column;
  padding: 4vw;
  .backButton {
    width: 100%;
      margin-bottom: 2%;
      a {
        color: #878b98;
        text-decoration: none;
        font-size: 1em;
        line-height: 1.2;
        span {
          font-size: 1.6em;
          vertical-align: middle;
          margin-right: 0.5em;
        }
      }
  }
  .previewImage {
    position: relative;
    cursor: pointer;
    &:before {
      content: "";
      padding-top: 56%;
      display: block;
    }
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .name {
    margin-bottom: 2%;
    overflow: hidden;
    .title {
      padding-left: 2%;
      font-size: 1.6em;
    }
    .base_field {
      padding-left: 2%;
    }
    .instruction_field {
      text-decoration: none;
      text-align: center;
      padding: 0 1em;
      line-height: 1.6;
      color: #fdbe06;
      border: 1px solid #fdbe06;
    }
  }
}
.gallery {
  flex: 0 0 100%;
  position: relative;
  overflow: hidden;
  .mainImageWrapper {
    position: relative;
    width: 100%;
    margin-bottom: 1%;
    &:before {
      content: "";
      padding-top: 56%;
      display: block;
    }
  }
}
.buttonPosition {
  position: absolute;
  top: 50%;
  width: 100%;
  left: 0;
  display: flex;
  align-items: center;
  z-index: 1;
  justify-content: space-between;
  height: 1px;
  .readOn button {
    opacity: 0;
    pointer-events: none;
  }
  > button:last-child {
    margin-right: 0;
  }
  button {
    margin-right: 1vw;
  }


}
@media (min-width: 768px) {
  .staffItem {
    display: block;
    flex-direction: row;
    flex-wrap: wrap;
    .backButton {

    }
  }
  .gallery {
    float: left;
    width: 49%;
  }

  .name {
    float: right;
    width: 49%;
  }
}

