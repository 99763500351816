.button {
  border: 1px solid #FFFFFF;
  border-radius: 6vw;
  width: 49vw;
  height: 11.5vw;
  font-size: 4.3vw;
  color: white;
  background-color: transparent;
  font-weight: 400;
  font-family: Manrope, serif;
  transition: all 0.1s ease-in 0s;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  --btn-color: #a0a0a0;
  &.large {
    width: 85vw;
  }
  &.small {
    font-size: 3.2vw;
    width: auto;
    height: 8.9vw;
    padding: 0 2.5em;
  }
  &.fullWidth {
    width: 100%;
  }
  &:active {
    border-color: #1a1a1a;
  }
  &.blue {
    background-color: #2C5CBD;
    border-color: #2C5CBD;
  }
  &.white {
    background-color: white;
    border-color: transparent;
  }
  &.semiWhite {
    background-color: rgba(255, 255, 255, 0.9);
    border-color: var(--btn-color);
    &.arrowRight:before {
      background: url(./assets/arrow--right--gray.svg) no-repeat center;
      background-size: contain;
    }
    &.arrowLeft:before {
      background: url(./assets/arrow--left--gray.svg) no-repeat center;
      background-size: contain;
    }
  }
  &.blueBorder {
    border-color: #2C5CBD;
    color: #2C5CBD;
  }
  &.shadow {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  }
  &.circle {
    width: 2.2vw;
    height: 2.2vw;
    border-radius: 50%;
  }
  &.rounded {
    width: 11.2vw;
    height: 11.2vw;
    border-radius: 22%;
  }
  &.noBorder {
    border: none;
  }
  &.gray {
    border-color: #969696;
    &.arrowRight:before {
      background: url(./assets/arrow--right--gray.svg) no-repeat center;
      background-size: contain;
    }
    &.arrowLeft:before {
      background: url(./assets/arrow--left--gray.svg) no-repeat center;
      background-size: contain;
    }
  }
  &.lightGray {
    border-color: #6A6A6A;
  }
  &.arrowLeft {
    display: flex;
    align-items: center;
    justify-content: center;
    &:before {
      content: '';
      width: 60%;
      height: 60%;
      display: block;
      background: url(./assets/arrow--left.svg) no-repeat center;
      background-size: contain;
    }
  }
    &.arrowRight {
      display: flex;
      align-items: center;
      justify-content: center;
      &:before {
        content: '';
        width: 60%;
        height: 60%;
        display: block;
        background: url(./assets/arrow--right.svg) no-repeat center;
        background-size: contain;
      }
    }
}