:global {
  .usefulCompItem {
    overflow: hidden;
    &__staffItem {
      padding: 3% 12% 0 7%;
      overflow: hidden;
      &__wrapper {
        max-width: 890px;
      }
      .image-gallery-swipe {
        border: 1px solid #d9d8d8;
      }
      .image-gallery-left-nav, .image-gallery-right-nav {
        color: #fff;
        font-size: 1.6em;
        padding: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      .image-gallery-left-nav {
        left: -1em;
      }
      .image-gallery-right-nav {
        right: -1em;
      }
      .image-gallery-fullscreen-button:before, .image-gallery-left-nav:before, .image-gallery-play-button:before, .image-gallery-right-nav:before {
        color: #e4e4e4;
        line-height: .7;
        text-shadow: none;
        transition: color .2s ease-out;
        border: 1px solid transparent;
      }
      .image-gallery-thumbnails .image-gallery-thumbnails-container {
        text-align: left;

      }
      .image-gallery-thumbnail {
        width: 22%;
        border: 1px solid transparent;
      }
      .image-gallery-thumbnail.active {
        border: 1px solid #337ab7;
      }
    }
    &__backButton {
      margin-bottom: 2%;
      a {
        color: #fdbe06;
        text-decoration: none;
        font-size: 1em;
        line-height: 1.2;
        span {
          font-size: 1.6em;
          vertical-align: middle;
          margin-right: 0.5em;
        }
      }
    }
    &__gallery {
      width: 100%;
      max-width: 600px;
      margin-bottom: 2%;
    }
    &__title {
      width: 100%;
      margin-bottom: 2%;
      overflow: hidden;
      font-size: 2em;
    }
    &__content {
      color: #2c2c2c;
    }
  }
  @media(max-width: 768px) {
    .usefulCompItem {
      .mainImage {
        img {
          width: 100%;
          height: auto;
        }
      }
      iframe {
        width: 100%;
        height: auto;
      }
    }

  }
}
