.mainImage {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  cursor: zoom-in;
  .preview, .preload {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .original {
    display: block;
    position: absolute;
    left: -50%;
    top: -50%;
    width: 200%;
    height: 200%;
    opacity: 0;
    pointer-events: none;
    transition: all 1000ms 200ms;
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &.magnifierMode:hover {
    cursor: grabbing;
    .original {
      opacity: 1;
      transition: opacity 250ms;
    }
    .preview, img.preload {
      opacity: 0;
      transition: opacity 250ms;
    }
  }
  .cursor {
    position: absolute;
    left: 0;
    bottom: 0;
  }

}