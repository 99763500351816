@import "/src/styles/vars";

.homeScreens {
  position: relative;
  min-height: 100%;
  width: 100%;
  overflow: hidden;
  margin: auto;
  z-index: 41;
}

.screens {
  display: none;
}

.mobileSwiper {
  display: block;
  width: 100%;
  height: 100%;

  .mobileText {
    font-family: Magistral-Book, serif;
    text-align: center;
    font-size: 5.8vw;
    font-weight: 500;
    line-height: 1.6;
    margin-bottom: 4vw;
  }

  .mobileBtn {
    text-align: center;
    font-size: 3.8vw;
    font-weight: 600;
    line-height: 1.6;
    height: calc(40 * $mobileSizeVW);
    text-transform: uppercase;
    border: 1px solid black;
    border-radius: 30px;
    width: max-content;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1em;
  }
}

.mobileScreen {
  padding-top: 143%;

  &:before {
    padding-top: 125%;
    content: '';
    display: block;
    background: black no-repeat center;
    background-size: cover;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }

  &.first {
    &:before {
      background-image: url(./assets/mobile_screen_1.png);
    }
  }

  &.second {
    &:before {
      background-image: url(./assets/mobile_screen_2.png);
    }
  }

  &.third {
    &:before {
      background-image: url(./assets/mobile_screen_3.png);
    }

    .text {
      top: 14%;
    }
  }

  .text {
    position: absolute;
    left: 0;
    top: 42%;
    width: 100%;
    text-align: center;
    color: white;
    font-size: 7.3vw;
    text-transform: uppercase;
  }
}

@media(min-width: 768px) {
  .screens {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    display: flex;
    align-items: flex-start;

  }
  .mobileSwiper {
    display: none;
  }
  .screen {
    position: relative;
    top: 0;
    width: 100%;
    flex: 0 0 100%;
    height: auto;
    overflow: hidden;
    z-index: auto;
    padding: 0 6.5% 0 10%;
    transition: all 0.5s ease-in;
    .wrapper {
      position: relative;

      .info {
        //
        .title {
          //font-weight: 700;
          font-size: 1.9em;
          line-height: 1;
        }

        p {
          margin: 0 0 1em 0;
        }

        h1 {
          font-size: 1.9em;
          line-height: 1;
          margin-bottom: 4%;
          font-weight: normal;
        }
      }
    }

    &.about {
      background: url('./assets/bgr--about.png') no-repeat center top;
      background-size: 100%;
      padding-top: 4.5%;

      .wrapper {
        width: 38%;
      }

      .info {

        color: black;

        .titles {
          width: fit-content;
          margin-bottom: 0.5em;
        }

        .title {
          color: black;
          font-family: Magistral-Book, serif;
          text-align: right;
          font-size: calc(33 * $desctopSizeVW);
          line-height: 1;
          text-transform: uppercase;

          & + .title {
            color: #7b7b7b;
            white-space: nowrap;
            font-size: calc(17.4 * $desctopSizeVW);
            text-align: right;
          }
        }

        .text {
          font-size: calc(11 * $desctopSizeVW);
        }
      }
    }

    &.geography {
      margin-left: -100%;
      &.selected {
        margin-left:0;
      }

      .wrapper {
        background: url('./assets/screen__bgr--map.png') no-repeat center;
        background-size: contain;
        padding-top: 50%;
      }

      .info {
        padding: 0;
        display: none;

        .text {
          color: white;

          &.cities {
            padding-left: 10%;
            color: white;

            p {
              margin: 0 0 0.4em 0;
              width: 49%;
              display: inline-block;
              vertical-align: top;
            }
          }
        }
      }
    }
  }
}

@media(min-width: 1920px) {
  .homeScreens {

  }
}