.contactsComp {
  margin-top: 15%;
  .description {
    overflow: hidden;
    margin-bottom: 1%;

    &:empty {
      display: none;
    }

    .description {
      color: #a7a2a2;
      margin-bottom: 1%;
    }
  }

  .items {
    padding: 1% 12% 0 7%;
    overflow: hidden;
  }
}
@media(min-width: 768px) {
  .contactsComp {
    margin-top: 0;
  }
}