@import "/src/styles/vars";

.lampItem {
  width: 100%;
  display: block;
  text-decoration: none;
  font-size: $mobileSizeVW;

  .image {
    position: relative;
    width: 100%;
    padding-top: 100%;
    overflow: hidden;
    border: solid 1px #535a6d;
    border-radius: 20%;

    img {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .title {
    text-align: center;
    color: black;
    position: relative;
    font-size: 12em;
    font-family: Magistral-Book, serif;
    line-height: 1.2;
    white-space: nowrap;
  }

  .description {
    width: 88%;
    margin: 0 auto 14% auto;
    line-height: 1.2;
    overflow: hidden;
    height: 2.4em;
    color: #747474;
    display: none;
  }

  .readon {
    display: none;
    text-align: center;
    color: black;
    background-color: #fdbe06;
    line-height: 2.6;
    width: 65%;
    margin: 0 auto 6% auto;
  }
}

@media(min-width: 768px) {
  .lampItem {
    font-size: $desctopSizeVW;
    border: none;
    width: 100%;
    text-decoration: none;

    .image {
      position: relative;
      width: 100%;
      padding-top: 100%;
      overflow: hidden;

      img {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .title {
      text-align: center;
      color: black;
      position: relative;
      margin-bottom: 1%;
      font-size: 12em;
      div {
        position: absolute;
        bottom: 0;
        width: 88%;
        left: 6%;
        line-height: 1.2;
        font-size: 1em;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .description {
      width: 88%;
      margin: 0 auto 14% auto;
      line-height: 1.2;
      overflow: hidden;
      height: 2.4em;
      color: #747474;
    }

    .readon {
      text-align: center;
      color: black;
      background-color: #fdbe06;
      line-height: 2.6;
      width: 65%;
      margin: 0 auto 6% auto;
    }
  }
}
