.headerComp {
    position: relative;
    width: 100%;
    background-color: #d9d8d8;
    overflow: hidden;
    padding-left: 7%;
    padding-right: 3%;
    z-index: 2;
  margin-top: 2%;
    &:before {
      content: '';
      float: left;
      padding-top: 7%;
    }
    .breadcrumbs {
      white-space: nowrap;
      font-size: 1em;
      width: 100%;
      margin-top: 1.5%;
      div.divider {
        width: 1em;
        height: 1em;
        background-image: url('../Header/assets/breadcrumbarrow.png');
        background-size: contain;
      }
      a, div {
        display: inline-block;
        color: #a4a3a3;
        text-decoration: none;
        margin:  0 0.4em 0 0;
        line-height: 1;
        vertical-align: middle;
      }
    }
    .sectionTitle {
      float: left;
      font-size: 1.3em;
      line-height: 2;
    }
    .searchBlock {
      float: right;
      white-space: nowrap;
      width: 29%;
      border-bottom: 1px solid #414040;
      position: relative;
      display: none;
      a {
        position: absolute;
        width: 9%;
        height: 100%;
        right: 0;
        top: 0;
        background-image: url('../Header/assets/loupe.png');
        background-size: cover;
      }
      input {
        display: block;
        border: none;
        font-size: 1em;
        line-height: 1.6;
        width: 100%;
        background-color: transparent;
      }
    }
  }
  @media(min-width: 768px) {
    .headerComp {
      .searchBlock {
        display: block;
      }
    }
  }


