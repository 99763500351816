@import "/src/styles/vars";

.screenContainer {
  width: 100%;
  min-height: 100dvh;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 40;
  }

}

.menu {
  position: fixed;
  height: 100%;
  width: 0;
  overflow: hidden;
  transition: all 0.25s ease;
  z-index: 3;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  left: 0;
  top: 0;

  .spacer {
    flex: 2;
  }

  &.open {
    width: 80%;
    padding: 24% 0;
  }

  .catalogueHeader {
    display: none;
  }

  .link {
    display: block;
    font-family: "Helvetica Neue", sans-serif;
    font-size: calc(16 * $mobileSizeVW);
    text-decoration: none;
    text-transform: uppercase;
    color: #353649;
    border-bottom: 1px solid #797777;
    padding: 2em 0 0.2em 1em;
  }

  .socialNets {
    position: relative;
    z-index: 2;
    padding-left: 10%;
    text-align: center;

    a {
      display: inline-block;
      width: 1.7em;
      height: 1.7em;
      margin: 0 0.6em 0 0.6em;
      background-size: contain;
      background-repeat: no-repeat;
      color: transparent;

      &.vc {
        background-image: url('../CoreLayout/assets/social--vc.png');
      }

      &.f {
        background-image: url('../CoreLayout/assets/social--f.png');
      }

      &.inst {
        background-image: url('../CoreLayout/assets/social--inst.png');
      }

      &.youtube {
        background-image: url('../CoreLayout/assets/social--y.png');
      }
    }
  }

}

.header {
  display: flex;
  position: absolute;
  top: 0;
  align-items: center;
  justify-content: center;
  height: calc(54 * $mobileSizeVW);
  width: 100%;
  z-index: 42;
  background-color: transparent;

  .column {
    flex: 74;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .logo {
    flex: 231;
    display: block;
    text-decoration: none;
    position: relative;
    z-index: 10;
    background: url(./assets/hivision-logo_2024.png) center no-repeat;
    background-size: contain;
    transition: all 0.25s ease;

    &:before {
      content: '';
      display: block;
      position: relative;
      padding-top: 20%;
    }

    &.second {
      filter: invert(80%);
    }

  }

  &.open {
    .logo {
      filter: invert(80%);
    }

    .hamburger {
      background-image: url('./assets/hamburger_closed.png');
    }
  }
}

.hamburger {
  position: relative;
  z-index: 100;
  top: 0;
  right: 0;
  width: calc(20 * $mobileSizeVW);
  background-image: url('./assets/hamburger_opened.png');
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;

  &:before {
    content: '';
    display: block;
    position: relative;
    padding-top: 50%;
  }

  &.second {
    filter: invert(80%);
  }
}

@media(min-width: 768px) {

  .screenContainer {
    background: url(./assets/bgr__left--down.png) bottom left no-repeat;
    background-size: contain;
    &.home {
      &:after {
        background: url(./assets/bgr__center.png) top center no-repeat;
        background-size: contain;
      }
    }
  }

  .screenContainer {
    .hamburger {
      display: none;
    }

    .header {
      display: flex;
      position: relative;
      align-items: flex-end;
      justify-content: space-between;
      height: 8.14vw;
      padding: 0 5% 0 6%;
      z-index: 50;

      .column {
        display: none;
      }

      .logo {
        flex: 0 0 27%;
        //background: url(./assets/hivision-logo_2024_desctop.png) no-repeat left;
        background-size: cover;
        filter: invert(80%);
        z-index: 30;
        &:before {
          padding-top: 24%;
        }
        top: 10%;
      }

    }
    .wrapper {
      height:auto;
      min-height: calc(100dvh - 78 * $desctopSizeVW);
      position: relative;
    }
  }
  .menu {
    position: relative;
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: calc(20 * $desctopSizeVW);
    background-color: transparent;
    z-index: 10;
    padding-bottom: 1%;

    .spacer {
      display: none;
    }

    .link {

      font-size: calc(7 * $desctopSizeVW);
      font-weight: 600;
      white-space: nowrap;
      letter-spacing: 0.05em;
      border-bottom: none;
      vertical-align: middle;
      padding: 0.2em 0.4em;
      z-index: 10;

      &:hover {
        background-color: #d5d5d5;

        & + .catalogueHeader {
          display: block;
          opacity: 1;
          pointer-events: auto;
        }
      }
    }

    .catalogueHeader {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      z-index: 1;
      opacity: 0;
      pointer-events: none;
      display: block;
      background-color: white;
      box-shadow: 0 4px 8px 0 rgba(34, 60, 80, 0.2);

      &:hover:not(.disabledHover) {
        opacity: 1;
        pointer-events: auto;
      }

      .content {
        max-width: 1920px;
        width: 100%;
        margin: auto;
        display: flex;
        gap: calc(15 * $desctopSizeVW);
        padding-top: calc(78 * $desctopSizeVW);
        align-items: center;
        justify-content: center;
        padding-bottom: 4%;

        .item {
          width: calc(75 * $desctopSizeVW);
          height: calc(75 * $desctopSizeVW);
          border-radius: 20%;
          overflow: hidden;
          border: 1px solid black;
          cursor: pointer;
          position: relative;

          .image {
            width: 100%;
            height: 100%;
            object-fit: cover;
            background: url('./assets/default_logo_catalogue.png') no-repeat center center;
            background-size: cover;
            position: relative;

            img {
              object-fit: cover;
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%
            }

          }

          .title {
            position: absolute;;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: calc(6 * $desctopSizeVW);
            color: white;
            text-align: center;
            text-transform: uppercase;
            font-family: "MyriadProRegular", serif;
            padding: 0 1em;
          }
        }
      }
    }

    .socialNets {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0;
      gap: calc(14 * $desctopSizeVW);

      a {
        width: calc(18 * $desctopSizeVW);
        height: calc(18 * $desctopSizeVW);
        margin: 0;
      }
    }
  }
}
.jebloWrapper {
  position: fixed;
  bottom: 1em;
  right: 2em;
  display: flex;
  justify-content: flex-end;
  padding: 3% 7% 3% 3%;
  z-index: 41;
}
