:global {
  .commonSection {
    margin-top: 15%;

    .wrapper {
      padding: 0 7%;
    }

    &__filter {
      position: relative;
      background-color: #eae9e9;
      border-radius: 2em;
      white-space: nowrap;
      padding: .4% 1.2%;

      a {
        display: inline-block;
        color: black;
        font-size: 0.8em;
        text-decoration: none;
        vertical-align: middle;
        margin-right: 6%;

        &.all {
          margin-left: 3%;
          width: 30%;
        }

        &.video, &.news, &.articles {
          width: 4em;
          height: 2.5em;
          background-color: #fff;
          border-radius: 2em;
          border: 1px solid #d9d8d8;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: contain;

          &.active {
            background-color: #d9d8d8;
          }
        }

        &.video {
          background-image: url('./assets/icon--video.png');
        }

        &.news {
          background-image: url('./assets/icon--news.png');
        }

        &.articles {
          background-image: url('./assets/icon--article.png');
        }
      }
    }

    &__description {
      overflow: hidden;
      margin-bottom: 1%;

      &:empty {
        display: none;
      }
    }

    &__items {
      padding: 0;
      overflow: hidden;
    }

    &__item {
      width: 100%;
      position: relative;
      overflow: hidden;
      float: left;
      margin-right: 0;
      margin-bottom: 10%;
      border: solid 1px #c1c1c1;

      &:before {
        content: '';
        display: block;
        position: relative;
        padding-top: 142%;
      }

      .readon {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: block;
        z-index: 21;
      }


      .description {
        position: absolute;
      }

      .readon {
        position: absolute;
        text-align: center;

        a {
          text-align: center;
          background-color: #fdbe06;
          display: inline-block;
          line-height: 2;
          text-decoration: none;
          padding: 0 2em 0 2em;
          color: black;
          border-radius: 0.2em;
        }
      }

      &.common {
        .date {
          background-image: url('./assets/icon-item--article.png');
        }

        .image {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;

          &__wrapper {
            position: relative;
            width: 150%;
            height: 100%;
            margin-left: -25%;
            text-align: center;

            img {
              position: relative;
              display: inline-block;
              width: auto;
              height: 100%;
            }
          }
        }

        .title {
          font-family: "MyriadProSemiBold", serif;
          font-size: 1.5em;
          position: absolute;
          width: 80%;
          left: 10%;
          top: 60%;
          color: #fff;
          z-index: 20;
          height: 25%;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .second-title {
          font-size: 1.5em;
          position: absolute;
          width: 80%;
          left: 10%;
          top: 85%;
          color: #fff;
          z-index: 20;
          height: 10%;
          overflow: hidden;
          text-overflow: ellipsis;

          &--links {
            font-size: 1em;
            text-decoration: underline;
          }
        }

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.2) 14%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#80000000', endColorstr='#000000', GradientType=0); /* IE6-9 */
        }
      }

      &.news {
        .date {
          background-image: url('./assets/icon-item--news.png');
        }

        .image {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;

          &__wrapper {
            position: relative;
            width: 150%;
            height: 100%;
            margin-left: -25%;
            text-align: center;

            img {
              position: relative;
              display: inline-block;;
              width: auto;
              height: 100%;
            }
          }
        }

        .title {
          font-family: "MyriadProSemiBold", serif;
          font-weight: 600;
          font-size: 1.5em;
          position: absolute;
          width: 80%;
          left: 10%;
          top: 60%;
          color: #fff;
          z-index: 20;
          height: 25%;
          overflow: hidden;
          text-overflow: ellipsis;
          line-height: 1.2;
        }

        .second-title {
          font-size: 1.5em;
          position: absolute;
          width: 80%;
          left: 10%;
          top: 85%;
          color: #fff;
          z-index: 20;
          height: 10%;
          overflow: hidden;
          text-overflow: ellipsis;

          &--links {
            font-size: 1em;
            text-decoration: underline;
          }
        }

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          height: 100%;
          width: 100%;
          /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0.5+0,0.2+14,0+25,1+100 */
          background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.2) 14%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#80000000', endColorstr='#000000', GradientType=0); /* IE6-9 */

        }
      }

      &.video {
        .date {
          color: #a0a0a0;
          background-image: url('./assets/icon-item--video.png');
        }

        .image {
          position: absolute;
          width: 100%;
          height: 40%;
          left: 0;
          top: 12%;

          &__wrapper {
            position: relative;
            width: 100%;
            height: 100%;

            img {
              position: relative;
              display: inline-block;;
              width: 100%;
              height: 100%;
            }
          }
        }

        .title {
          font-family: "MyriadProSemiBold", serif;
          font-size: 1.5em;
          position: absolute;
          width: 83%;
          left: 7%;
          top: 60%;
          color: #000000;
          z-index: 20;
          height: 25%;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .second-title {
          font-size: 1.5em;
          position: absolute;
          width: 80%;
          left: 10%;
          top: 85%;
          color: black;
          z-index: 20;
          height: 10%;
          overflow: hidden;
          text-overflow: ellipsis;

          &--links {
            font-size: 1em;
            text-decoration: underline;
          }
        }
      }

      .date {
        position: absolute;
        left: 8%;
        top: 3%;
        z-index: 23;
        color: #fff;
        font-size: 0.9em;
        line-height: 1.8;
        padding-left: 17%;
        background-repeat: no-repeat;
        background-position: 0;
        background-size: contain;
      }

    }
  }

  @media(min-width: 768px) {
    .commonSection {
      margin-top: 0;
    }
    .usefulComp {
      .wrapper {
        padding: 0;
      }

      &__filter {
        position: absolute;
        left: 6%;
        top: 5%;
        background-color: #eae9e9;
        border-radius: 2em;
        white-space: nowrap;
        padding: .4% 1.2%;
        width: 25%;

        a {
          display: inline-block;
          color: black;
          font-size: 0.8em;
          text-decoration: none;
          vertical-align: middle;
          margin-right: 6%;

          &.all {
            margin-left: 3%;
            width: 30%;
          }

          &.video, &.news, &.articles {
            width: 4em;
            height: 2.5em;
            background-color: #fff;
            border-radius: 2em;
            border: 1px solid #d9d8d8;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;

            &.active {
              background-color: #d9d8d8;
            }
          }

          &.video {
            background-image: url('./assets/icon--video.png');
          }

          &.news {
            background-image: url('./assets/icon--news.png');
          }

          &.articles {
            background-image: url('./assets/icon--article.png');
          }
        }
      }

      &__description {
        overflow: hidden;
        margin-bottom: 1%;

        &:empty {
          display: none;
        }
      }

      &__items {
        padding: 8% 12% 0 7%;
        overflow: hidden;
      }

      &__item {
        width: 23%;
        position: relative;
        overflow: hidden;
        float: left;
        margin-right: 1.8%;
        margin-bottom: 2%;
        border: solid 1px #c1c1c1;

        &:before {
          content: '';
          display: block;
          position: relative;
          padding-top: 142%;
        }

        .readon {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          display: block;
          z-index: 21;
        }


        .description {
          position: absolute;
        }

        .readon {
          position: absolute;
          text-align: center;

          a {
            text-align: center;
            background-color: #fdbe06;
            display: inline-block;
            line-height: 2;
            text-decoration: none;
            padding: 0 2em 0 2em;
            color: black;
            border-radius: 0.2em;
          }
        }

        &.common {
          .date {
            background-image: url('./assets/icon-item--article.png');
          }

          .image {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;

            &__wrapper {
              position: relative;
              width: 150%;
              height: 100%;
              margin-left: -25%;
              text-align: center;

              img {
                position: relative;
                display: inline-block;
                width: auto;
                height: 100%;
              }
            }
          }

          .title {
            font-family: "MyriadProSemiBold", serif;
            font-size: 1.5em;
            position: absolute;
            width: 80%;
            left: 10%;
            top: 60%;
            color: #fff;
            z-index: 20;
            height: 25%;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .second-title {
            font-size: 1.5em;
            position: absolute;
            width: 80%;
            left: 10%;
            top: 85%;
            color: #fff;
            z-index: 20;
            height: 10%;
            overflow: hidden;
            text-overflow: ellipsis;

            &--links {
              font-size: 1em;
              text-decoration: underline;
            }
          }

          &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            height: 100%;
            width: 100%;
            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0.5+0,0.2+14,0+25,1+100 */
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.2) 14%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#80000000', endColorstr='#000000', GradientType=0); /* IE6-9 */

          }
        }

        &.news {
          .date {
            background-image: url('./assets/icon-item--news.png');
          }

          .image {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;

            &__wrapper {
              position: relative;
              width: 150%;
              height: 100%;
              margin-left: -25%;
              text-align: center;

              img {
                position: relative;
                display: inline-block;;
                width: auto;
                height: 100%;
              }
            }
          }

          .title {
            font-family: "MyriadProSemiBold", serif;
            font-weight: 600;
            font-size: 1.5em;
            position: absolute;
            width: 80%;
            left: 10%;
            top: 60%;
            color: #fff;
            z-index: 20;
            height: 25%;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 1.2;
          }

          .second-title {
            font-size: 1.5em;
            position: absolute;
            width: 80%;
            left: 10%;
            top: 85%;
            color: #fff;
            z-index: 20;
            height: 10%;
            overflow: hidden;
            text-overflow: ellipsis;

            &--links {
              font-size: 1em;
              text-decoration: underline;
            }
          }

          &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            height: 100%;
            width: 100%;
            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0.5+0,0.2+14,0+25,1+100 */
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.2) 14%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#80000000', endColorstr='#000000', GradientType=0); /* IE6-9 */

          }
        }

        &.video {
          .date {
            color: #a0a0a0;
            background-image: url('./assets/icon-item--video.png');
          }

          .image {
            position: absolute;
            width: 100%;
            height: 40%;
            left: 0;
            top: 12%;

            &__wrapper {
              position: relative;
              width: 100%;
              height: 100%;

              img {
                position: relative;
                display: inline-block;;
                width: 100%;
                height: 100%;
              }
            }
          }

          .title {
            font-family: "MyriadProSemiBold", serif;
            font-size: 1.5em;
            position: absolute;
            width: 83%;
            left: 7%;
            top: 60%;
            color: #000000;
            z-index: 20;
            height: 25%;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .second-title {
            font-size: 1.5em;
            position: absolute;
            width: 80%;
            left: 10%;
            top: 85%;
            color: black;
            z-index: 20;
            height: 10%;
            overflow: hidden;
            text-overflow: ellipsis;

            &--links {
              font-size: 1em;
              text-decoration: underline;
            }
          }
        }

        .date {
          position: absolute;
          left: 8%;
          top: 3%;
          z-index: 23;
          color: #fff;
          font-size: 0.9em;
          line-height: 1.8;
          padding-left: 17%;
          background-repeat: no-repeat;
          background-position: 0;
          background-size: contain;
        }

      }
    }
  }

}
