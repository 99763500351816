@import 'base';
@import '~normalize.css/normalize';

@font-face {
  font-family: "MyriadProRegular";
  src: url("assets/fonts/MyriadProRegular/MyriadProRegular.eot");
  src: url("assets/fonts/MyriadProRegular/MyriadProRegular.eot?#iefix")format("embedded-opentype"),
  url("assets/fonts/MyriadProRegular/MyriadProRegular.woff") format("woff"),
  url("assets/fonts/MyriadProRegular/MyriadProRegular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "MyriadProBold";
  src: url("assets/fonts/MyriadProBold/MyriadProBold.eot");
  src: url("assets/fonts/MyriadProBold/MyriadProBold.eot?#iefix")format("embedded-opentype"),
  url("assets/fonts/MyriadProBold/MyriadProBold.woff") format("woff"),
  url("assets/fonts/MyriadProBold/MyriadProBold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "MyriadProItalic";
  src: url("assets/fonts/MyriadProItalic/MyriadProItalic.eot");
  src: url("assets/fonts/MyriadProItalic/MyriadProItalic.eot?#iefix")format("embedded-opentype"),
  url("assets/fonts/MyriadProItalic/MyriadProItalic.woff") format("woff"),
  url("assets/fonts/MyriadProItalic/MyriadProItalic.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "MyriadProSemiBold";
  src: url("assets/fonts/MyriadProSemiBold/MyriadProSemiBold.eot");
  src: url("assets/fonts/MyriadProSemiBold/MyriadProSemiBold.eot?#iefix")format("embedded-opentype"),
  url("assets/fonts/MyriadProSemiBold/MyriadProSemiBold.woff") format("woff"),
  url("assets/fonts/MyriadProSemiBold/MyriadProSemiBold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "MyriadProBoldItalic";
  src: url("assets/fonts/MyriadProBoldItalic/MyriadProBoldItalic.eot");
  src: url("assets/fonts/MyriadProBoldItalic/MyriadProBoldItalic.eot?#iefix")format("embedded-opentype"),
  url("assets/fonts/MyriadProBoldItalic/MyriadProBoldItalic.woff") format("woff"),
  url("assets/fonts/MyriadProBoldItalic/MyriadProBoldItalic.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "MyriadProSemiBoldItalic";
  src: url("assets/fonts/MyriadProSemiBoldItalic/MyriadProSemiBoldItalic.eot");
  src: url("assets/fonts/MyriadProSemiBoldItalic/MyriadProSemiBoldItalic.eot?#iefix")format("embedded-opentype"),
  url("assets/fonts/MyriadProSemiBoldItalic/MyriadProSemiBoldItalic.woff") format("woff"),
  url("assets/fonts/MyriadProSemiBoldItalic/MyriadProSemiBoldItalic.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "Open Sans";
  src: url('assets/fonts/opensans-300-normal.woff2') format('woff2'),
  url('assets/fonts/opensans-300-normal.woff') format('woff'),
  url('assets/fonts/opensans-300-normal.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: url('assets/fonts/opensans-300-italic.woff2') format('woff2'),
  url('assets/fonts/opensans-300-italic.woff') format('woff'),
  url('assets/fonts/opensans-300-italic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Open Sans";
  src: url('assets/fonts/opensans-600-italic.woff2') format('woff2'),
  url('assets/fonts/opensans-600-italic.woff') format('woff'),
  url('assets/fonts/opensans-600-italic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Roboto Slab";
  src: url('assets/fonts/robotoslab100.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Roboto Slab";
  src: url('assets/fonts/robotoslab300.woff2') format('woff2'),
  url('assets/fonts/robotoslab300.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Roboto Slab";
  src: url('assets/fonts/robotoslab400.woff2') format('woff2'),
  url('assets/fonts/robotoslab400.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Roboto Slab";
  src: url('assets/fonts/robotoslab700.woff2') format('woff2'),
  url('assets/fonts/robotoslab700.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

/*Roboto*/
@font-face {
  font-family: "Rouble";
  src: url('assets/fonts/rouble/roboto-100-normal.woff2') format('woff2'),
  url('assets/fonts/rouble/roboto-100-normal.woff') format('woff'),
  url('assets/fonts/rouble/roboto-100-normal.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Rouble";
  src: url('assets/fonts/rouble/roboto-300-normal.woff2') format('woff2'),
  url('assets/fonts/rouble/roboto-300-normal.woff') format('woff'),
  url('assets/fonts/rouble/roboto-300-normal.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Rouble";
  src: url('assets/fonts/rouble/roboto-400-normal.woff2') format('woff2'),
  url('assets/fonts/rouble/roboto-400-normal.woff') format('woff'),
  url('assets/fonts/rouble/roboto-400-normal.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Rouble";
  src: url('assets/fonts/rouble/roboto-500-normal.woff2') format('woff2'),
  url('assets/fonts/rouble/roboto-500-normal.woff') format('woff'),
  url('assets/fonts/rouble/roboto-500-normal.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Rouble";
  src: url('assets/fonts/rouble/roboto-700-normal.woff2') format('woff2'),
  url('assets/fonts/rouble/roboto-700-normal.woff') format('woff'),
  url('assets/fonts/rouble/roboto-700-normal.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Rouble";
  src: url('assets/fonts/rouble/roboto-900-normal.woff2') format('woff2'),
  url('assets/fonts/rouble/roboto-900-normal.woff') format('woff'),
  url('assets/fonts/rouble/roboto-900-normal.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
/*italic*/
@font-face {
  font-family: "Rouble";
  src: url('assets/fonts/rouble/roboto-100-italic.woff2') format('woff2'),
  url('assets/fonts/rouble/roboto-100-italic.woff') format('woff'),
  url('assets/fonts/rouble/roboto-100-italic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: "Rouble";
  src: url('assets/fonts/rouble/roboto-300-italic.woff2') format('woff2'),
  url('assets/fonts/rouble/roboto-300-italic.woff') format('woff'),
  url('assets/fonts/rouble/roboto-300-italic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Rouble";
  src: url('assets/fonts/rouble/roboto-400-italic.woff2') format('woff2'),
  url('assets/fonts/rouble/roboto-400-italic.woff') format('woff'),
  url('assets/fonts/rouble/roboto-400-italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Rouble";
  src: url('assets/fonts/rouble/roboto-500-italic.woff2') format('woff2'),
  url('assets/fonts/rouble/roboto-500-italic.woff') format('woff'),
  url('assets/fonts/rouble/roboto-500-italic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Rouble";
  src: url('assets/fonts/rouble/roboto-700-italic.woff2') format('woff2'),
  url('assets/fonts/rouble/roboto-700-italic.woff') format('woff'),
  url('assets/fonts/rouble/roboto-700-italic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Rouble";
  src: url('assets/fonts/rouble/roboto-900-italic.woff2') format('woff2'),
  url('assets/fonts/rouble/roboto-900-italic.woff') format('woff'),
  url('assets/fonts/rouble/roboto-900-italic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Roboto";
  src: url("assets/fonts/RobotoRegular.eot");
  src: url("assets/fonts/RobotoRegular.eot?#iefix")format("embedded-opentype"),
  url("assets/fonts/RobotoRegular.woff") format("woff"),
  url("assets/fonts/RobotoRegular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("assets/fonts/RobotoLight.eot");
  src: url("assets/fonts/RobotoLight.eot?#iefix")format("embedded-opentype"),
  url("assets/fonts/RobotoLight.woff") format("woff"),
  url("assets/fonts/RobotoLight.ttf") format("truetype");
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: 'Magistral-Book';
  src: url('assets/fonts/magistral-book.eot'); /* IE 9 Compatibility Mode */
  src: url('assets/fonts/magistral-book.eot?#iefix') format('embedded-opentype'), /* IE < 9 */
  url('assets/fonts/magistral-book.woff2') format('woff2'), /* Super Modern Browsers */
  url('assets/fonts/magistral-book.woff') format('woff'), /* Firefox >= 3.6, any other modern browser */
  url('assets/fonts/magistral-book.ttf') format('truetype');
}

// Some best-practice CSS that's useful for most apps
// Just remove them if they're not what you want
html {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

/* This stylesheet generated by Transfonter (https://transfonter.org) on August 21, 2017 1:40 PM */

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('assets/fonts/HelveticaNeueCyr-Medium.eot'),
  url('assets/fonts/HelveticaNeueCyr-Medium.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/HelveticaNeueCyr-Medium.woff') format('woff'),
  url('assets/fonts/HelveticaNeueCyr-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('assets/fonts/HelveticaNeueCyr-Heavy.eot'),
  url('assets/fonts/HelveticaNeueCyr-Heavy.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/HelveticaNeueCyr-Heavy.woff') format('woff'),
  url('assets/fonts/HelveticaNeueCyr-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('assets/fonts/HelveticaNeueCyr-HeavyItalic.eot'),
  url('assets/fonts/HelveticaNeueCyr-HeavyItalic.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/HelveticaNeueCyr-HeavyItalic.woff') format('woff'),
  url('assets/fonts/HelveticaNeueCyr-HeavyItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('assets/fonts/HelveticaNeueCyr-Black.eot'),
  url('assets/fonts/HelveticaNeueCyr-Black.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/HelveticaNeueCyr-Black.woff') format('woff'),
  url('assets/fonts/HelveticaNeueCyr-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('assets/fonts/HelveticaNeueCyr-UltraLightItalic.eot'),
  url('assets/fonts/HelveticaNeueCyr-UltraLightItalic.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/HelveticaNeueCyr-UltraLightItalic.woff') format('woff'),
  url('assets/fonts/HelveticaNeueCyr-UltraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('assets/fonts/HelveticaNeueCyr-BlackItalic.eot'),
  url('assets/fonts/HelveticaNeueCyr-BlackItalic.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/HelveticaNeueCyr-BlackItalic.woff') format('woff'),
  url('assets/fonts/HelveticaNeueCyr-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('assets/fonts/HelveticaNeueCyr-ThinItalic.eot'),
  url('assets/fonts/HelveticaNeueCyr-ThinItalic.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/HelveticaNeueCyr-ThinItalic.woff') format('woff'),
  url('assets/fonts/HelveticaNeueCyr-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('assets/fonts/HelveticaNeueCyr-MediumItalic.eot'),
  url('assets/fonts/HelveticaNeueCyr-MediumItalic.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/HelveticaNeueCyr-MediumItalic.woff') format('woff'),
  url('assets/fonts/HelveticaNeueCyr-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('assets/fonts/HelveticaNeueCyr-LightItalic.eot'),
  url('assets/fonts/HelveticaNeueCyr-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/HelveticaNeueCyr-LightItalic.woff') format('woff'),
  url('assets/fonts/HelveticaNeueCyr-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('assets/fonts/HelveticaNeueCyr-UltraLight.eot'),
  url('assets/fonts/HelveticaNeueCyr-UltraLight.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/HelveticaNeueCyr-UltraLight.woff') format('woff'),
  url('assets/fonts/HelveticaNeueCyr-UltraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('assets/fonts/HelveticaNeueCyr-BoldItalic.eot'),
  url('assets/fonts/HelveticaNeueCyr-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/HelveticaNeueCyr-BoldItalic.woff') format('woff'),
  url('assets/fonts/HelveticaNeueCyr-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('assets/fonts/HelveticaNeueCyr-Italic.eot'),
  url('assets/fonts/HelveticaNeueCyr-Italic.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/HelveticaNeueCyr-Italic.woff') format('woff'),
  url('assets/fonts/HelveticaNeueCyr-Italic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('assets/fonts/HelveticaNeueCyr-Roman.eot'),
  url('assets/fonts/HelveticaNeueCyr-Roman.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/HelveticaNeueCyr-Roman.woff') format('woff'),
  url('assets/fonts/HelveticaNeueCyr-Roman.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('assets/fonts/HelveticaNeueCyr-Bold.eot'),
  url('assets/fonts/HelveticaNeueCyr-Bold.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/HelveticaNeueCyr-Bold.woff') format('woff'),
  url('assets/fonts/HelveticaNeueCyr-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('assets/fonts/HelveticaNeueCyr-Light.eot'),
  url('assets/fonts/HelveticaNeueCyr-Light.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/HelveticaNeueCyr-Light.woff') format('woff'),
  url('assets/fonts/HelveticaNeueCyr-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('assets/fonts/HelveticaNeueCyr-Thin.eot'),
  url('assets/fonts/HelveticaNeueCyr-Thin.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/HelveticaNeueCyr-Thin.woff') format('woff'),
  url('assets/fonts/HelveticaNeueCyr-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}
