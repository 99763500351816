@import "/src/styles/vars";

.catalogueComp {
  .wrapper {
    padding: 16% 0;
  }
}

:global {
  .catalogueComp {
    position: relative;
    z-index: 41;
    &__header {
      font-size: $mobileSizeVW;
      width: 100%;
      position: relative;
      margin-top: 15%;
      margin-bottom: 1%;
      background: url('./assets/default_header_bgr.png') no-repeat center;
      background-size: cover;
      .content {
        padding-top: 15%;
        max-width: 1920px;
        margin: auto;
        position: relative;
        width: 100%;
        height: 100%;
        max-height: calc(1920/100*32px);
      }

      .image {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: auto;
        }
      }

      .text {
        position: absolute;
        color: white;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-family: Magistral-Book, serif;

        &__title {
          font-size: 12em;
          text-transform: uppercase;
          padding: 0 0 0 1em;
        }

        &__description {
          font-size: 1em;
        }
      }
    }

    &__content {
      padding: 0 7% 0 7%;
      margin: 0 auto 2% auto;
      max-width: 1920px;

      .rest {
        display: none;
      }

      .trigger {
        text-align: right;
        overflow: hidden;
        color: #343434;
        cursor: pointer;

        div {
          display: inline-block;
          vertical-align: middle;
          margin-right: 0.2em;
          position: relative;
          padding-left: 0.6em;

          &:before {
            content: '';
            position: absolute;
            right: 100%;
            top: 50%;
            width: 2000px;
            height: 1px;
            background-color: #343434;;
          }

        }

        &:after {
          content: '';
          width: 1em;
          height: 1em;
          background-image: url('./assets/arrow.png');
          background-size: contain;
          display: inline-block;
          vertical-align: middle;
        }
      }
      &.open {
        .trigger {
          &:after {
            transform: rotate(180deg);
          }
        }
        .rest {
          display: block;
        }
      }
    }
    &__sections {
      overflow: hidden;
      padding: 2% 7% 0 7%;
      display: grid;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 7vw;
      grid-template-columns: 1fr 1fr;
      align-items: flex-start;

      .section {
        flex: 0 0 47%;
        overflow: visible;
        text-decoration: none;
        position: relative;
        background-color: white;
        font-size: $mobileSizeVW;

        &__image {
          border: 1px solid #535a6d;
          border-radius: 20%;
          overflow: hidden;
          padding-top: 100%;
          position: relative;
          width: 100%;

          img {
            display: block;
            height: 100%;
            left: 0;
            object-fit: cover;
            position: absolute;
            top: 0;
            width: 100%;
          }
        }

        &__title {
          color: #000;
          font-family: Magistral-Book,serif;
          font-size: 10em;
          line-height: 1.2;
          position: relative;
          text-align: center;
          white-space: normal;
          min-height: 3.5em;
          display: flex;
          justify-content: center;
          align-items: center;

        }

        &__alter-title {
          width: 100%;
          height: 100%;
          display: none;
        }
      }
    }

    &__staff {
      overflow: hidden;
      font-size: calc(1 * $mobileSizeVW);
      max-width: 1920px;
      margin: auto;

      .items {
        padding: 2% 14% 0 14%;
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        column-gap: 86em;
        row-gap: 40em;

        .item {
          float: left;
          width: 20.5%;
          border: solid 1px #c1c1c1;
          margin-right: 1.4%;
          margin-bottom: 2%;
          box-shadow: -1px 1px 6px 0px rgba(0, 0, 0, 0.17);
          -webkit-box-shadow: -1px 1px 6px 0px rgba(0, 0, 0, 0.17);
          -moz-box-shadow: -1px 1px 6px 0px rgba(0, 0, 0, 0.17);
          text-decoration: none;

          &__image {
            position: relative;
            width: 100%;
            padding-top: 76%;
            overflow: hidden;

            img {
              display: block;
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: auto;
            }
          }

          &__title {
            text-align: left;
            color: black;
            position: relative;
            padding-top: 15%;
            margin-bottom: 1%;
            font-size: 1em;

            div {
              position: absolute;
              bottom: 0;
              width: 88%;
              left: 6%;
              line-height: 1.2;
              font-size: 1.4em;
            }
          }

          &__description {
            width: 88%;
            margin: 0 auto 14% auto;
            line-height: 1.2;
            overflow: hidden;
            height: 2.4em;
          }

          &__readon {
            text-align: center;
            color: black;
            background-color: #fdbe06;
            line-height: 2.6;
            width: 65%;
            margin: 0 auto 6% auto;
          }
        }
      }

    }
  }

  @media(min-width: 768px) {
    .catalogueComp {
      overflow: hidden;
      &__header {
        width: 100%;
        position: relative;

        margin-bottom: 1%;
        margin-top: 3%;
        background-color: #444442;
        .content {
          padding-top: 15%;
          max-width: 1920px;
          margin: auto;
          position: relative;
          width: 100%;
          height: 100%;
          max-height: calc(1920/100*15px);
        }

        .image {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;

          img {
            width: 100%;
            height: auto;
          }
        }

        .text {
          position: absolute;
          color: white;
          left: 4%;
          top: 0;
          height: 100%;
          width: auto;
          display: flex;
          align-items: center;

          &__title {
            font-size: calc(28 * $desctopSizeVW);
            padding: 0;
          }

          &__description {
            font-size: 1.33em;
          }
        }
      }

      &__content {
        padding: 0 10% 0 7%;
        margin-bottom: 2%;

        .rest {
          display: none;
        }

        .trigger {
          text-align: right;
          overflow: hidden;
          color: #343434;
          cursor: pointer;

          div {
            display: inline-block;
            vertical-align: middle;
            margin-right: 0.2em;
            position: relative;
            padding-left: 0.6em;

            &:before {
              content: '';
              position: absolute;
              right: 100%;
              top: 50%;
              width: 2000px;
              height: 1px;
              background-color: #343434;;
            }

          }

          &:after {
            content: '';
            width: 1em;
            height: 1em;
            background-image: url('./assets/arrow.png');
            background-size: contain;
            display: inline-block;
            vertical-align: middle;
          }
        }

        &.open {
          .trigger {
            &:after {
              transform: rotate(180deg);
            }
          }

          .rest {
            display: block;
          }
        }
      }

      &__sections {
        overflow: hidden;
        padding: 2% 7% 0 7%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        justify-content: center;

        .section {
          font-size: $desctopSizeVW;

          &__image {


            img {

            }
          }

          &__title {

          }

          &__alter-title {

          }
        }
      }

      &__staff {
        overflow: hidden;

        .items {
          font-size: $desctopSizeVW;
          padding: 2% 7% 0 7%;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
          gap: 34em;

          .item {
            width: 100%;
            border: solid 1px #c1c1c1;
            box-shadow: -1px 1px 6px 0px rgba(0, 0, 0, 0.17);
            -webkit-box-shadow: -1px 1px 6px 0px rgba(0, 0, 0, 0.17);
            -moz-box-shadow: -1px 1px 6px 0px rgba(0, 0, 0, 0.17);
            text-decoration: none;

            &__image {
              position: relative;
              width: 100%;
              padding-top: 76%;
              overflow: hidden;

              img {
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: auto;
              }
            }

            &__title {
              text-align: left;
              color: black;
              position: relative;
              padding-top: 15%;
              margin-bottom: 1%;
              font-size: 1em;

              div {
                position: absolute;
                bottom: 0;
                width: 88%;
                left: 6%;
                line-height: 1.2;
                font-size: 1.4em;
              }
            }

            &__description {
              width: 88%;
              margin: 0 auto 14% auto;
              line-height: 1.2;
              overflow: hidden;
              height: 2.4em;
            }

            &__readon {
              text-align: center;
              color: black;
              background-color: #fdbe06;
              line-height: 2.6;
              width: 65%;
              margin: 0 auto 6% auto;
            }
          }
        }

      }
    }
  }
  @media(min-width: 1920px) {
    .catalogueComp {
      margin: auto;
    }
  }
}
