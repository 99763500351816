:global {
  @keyframes loader-scale {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }
  .preloader {
    background: rgba(240, 240, 240, 0.7);
    opacity: 0;
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    &.active {
      pointer-events: auto;
      opacity: 1;
    }
    .circle {
      width: 56px;
      height: 56px;
      border: 4px solid #fdbe06;
      border-radius: 50%;
      position: relative;
      animation: loader-scale 1s ease-out infinite;
      top: 50%;
      margin: -28px auto 0 auto;
    }
  }
}