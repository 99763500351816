@import "/src/styles/vars";
  .jebloButton {
    position: relative;
    z-index: 15;
    .btn {
      position: relative;
      width: fit-content;
      cursor: pointer;
      margin: auto;
      font-family: "Helvetica Neue", sans-serif;
      font-weight: 500;
      font-size: calc(14 * $mobileSizeVW);
      border: 1px solid gray;
      padding: 0 1em 0 1em;
      border-radius: 2em;
      text-transform: uppercase;
      line-height: 2;
      background-color: white;
    }

  }
.frm {
  position: fixed;
  background-color: white;
  width: 100%;
  height: 100%;
  padding: 4%;
  left: 0;
  top: 0;
  z-index: 100;
  &.hidden {
    display: none;
  }
  .close {
    position: absolute;
    top: 1%;
    right: 1%;
    background-image: url('./assets/close-button.png');
    width: 28px;
    height: 28px;
  }
  h2 {
    text-align: center;
    margin: 0;
  }
  .text {
    text-align: center;
    font-size: 1em;
    line-height: 1.6;
    margin-bottom: 8%;
  }
  .input {
    white-space: nowrap;
    margin-bottom: 6%;
    overflow: hidden;
    &:before {
      content: '';
      display: inline-block;
      width: 12%;
      height: 2.2em;
      background-image: url('./assets/i-city.png');
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      vertical-align: middle;
    }
    &.city {
      &:before {
        background-image: url('./assets/i-city.png');
      }
    }
    &.phone {
      &:before {
        background-image: url('./assets/i-phone.png');
      }
    }
    &.email {
      &:before {
        background-image: url('./assets/i-mail.png');
      }
    }
    &.cooperationType {
      &:before {
        background-image: url('./assets/i-partner.png');
      }
    }
    &.name {
      &:before {
        background-image: url('./assets/i-men.png');
      }
    }
    input, select {
      background-color: white;
      border: 1px solid #d9d8d8;
      font-size: 1em;
      line-height: 2.2;
      padding: 0 1% 0 1%;
      width: 88%;
      border-radius: 4px;
      height: 2.2em;
      vertical-align: middle;
    }
  }
  .sendContact {
    margin: 4% auto;
    text-align: center;
    display: block;
    width: 50%;
    color: #000;
    border: 2px solid black;
    text-decoration: none;
    font-size: 1.2em;
    line-height: 1.6;
    border-radius: 4px;
    cursor: pointer;
  }
}
  @media(min-width: 768px) {
    .jebloButton {
      display: block;
      .btn {
        font-size: calc(10 * $desctopSizeVW);
        margin-left: auto;
      }

    }
    .frm {
      position: fixed;
      background-color: white;
      width: 50%;
      height: auto;
      padding: 4% 4% 1% 4%;
      border: 1px solid gray;
      left: 25%;
      top: 50px;

      z-index: 1000;
      .close {
        position: absolute;
        top: 10px;
        right: 10px;
        background-image: url('./assets/close-button.png');
        width: 28px;
        height: 28px;
        z-index: 100;
      }
    }
  }
